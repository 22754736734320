import React, { useState } from "react";
import LandingSearchPanel from "../../components/search/LandingSearchPanel";
import { searchBloodProps } from "../../lib/types";
import SearchResultsList from "./components/SearchResultsList";
import { Container } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const navigate = useNavigate();
  const [searching, setSearching] = useState(false);

  const handleSearch = (values: searchBloodProps) => {
    navigate(`/find-donors?${new URLSearchParams(values).toString()}`);
  };

  const handleReset = () => {
    setSearching(false);
  };

  return (
    <div>
      <Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            pt: { xs: 5, md: 20 },
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
              Be a <b>hero</b> in someone's story
            </Typography>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1 }}>
              donate blood and <b>save lives</b>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Welcome to our Blood Donation App, where every drop you give
              becomes a beacon of hope.
              <br />
              Join us in making a difference and providing the gift of life to
              those in need.
            </Typography>
          </Box>
          <Box
            sx={{
              height: { xs: "150px", md: "300px" },
              width: { xs: "50vw", md: "500px" },
              backgroundImage: 'url("/blood-donating-person.png")',
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              mx: "auto",
              mb: { xs: 10, md: "auto" },
            }}
          ></Box>
        </Box>
      </Container>
      <LandingSearchPanel onSubmit={handleSearch} onCancel={handleReset} />
      <Container sx={{ mt: 10 }}>
        <SearchResultsList loading={searching} />
      </Container>
    </div>
  );
};

export default Home