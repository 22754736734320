import {
  Box,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Donor } from "../../lib/types/donor";
import { PhoneOutlined } from "@mui/icons-material";

type Props = {
  donor: Donor;
};

const StyledDonorName = styled(Typography)(({ theme }) => ({
  textTransform: "capitalize",
}));

export const DonorSearchCard = ({ donor }: Props) => {
  return (
    <Card key={donor.id} variant="outlined">
      <CardContent>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <StyledDonorName key={donor.id} variant="body1">
            {donor.name}
          </StyledDonorName>
          <Box>
            <Tooltip title={`Call ${donor.name}`}>
              <IconButton>
                <PhoneOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DonorSearchCard;
