import { Skeleton } from "@mui/material"

type Props = {
    loading: boolean
}
export const SearchResultsList = ({ loading }: Props = { loading: false }) => {

    if (loading) {
        return (
            <div>
                {Array.from({ length: 8 }).map((_, index) => <Skeleton key={index} variant="rectangular" height={40} sx={{ mb: 1 }} />)}
            </div>
        )
    }
    return (
        <div>

        </div>
    )
}

export default SearchResultsList