import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LandingSearchPanel from "../../components/search/LandingSearchPanel";
import { searchBloodProps } from "../../lib/types";
import DonorSerachResults from "../../components/search/DonorSearchResults";
import useGetDonors from "../../lib/hooks/useGetDonors";

export const SearchLanding = () => {
  const [searching, setSearching] = useState(false);
  const [searchKeys, setSearchKeys] = useState<searchBloodProps>();
  const searchResults = useGetDonors(searchKeys ?? {});

  const handleSearch = (values: searchBloodProps) => {
    setSearching(true);
    setSearchKeys(values);
  };

  const handleReset = () => {
    setSearching(false);
  };

  useEffect(() => {
    document.title = "Search - Blood Bank";
  }, []);

  return (
    <Container>
      <LandingSearchPanel onSubmit={handleSearch} onCancel={handleReset} />
      <DonorSerachResults results={searchResults} />
    </Container>
  );
};

export default SearchLanding;
