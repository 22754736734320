import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    // primary: {
    //   main: "#d21936",
    // },
    text: {
      primary: "#444",
    },
  },
  typography: {
    h3: {
      fontWeight: 200,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
      styleOverrides: {},
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        fontWeight: 400,
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "text.primary",
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
    },
  },
});

export default theme