import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

const TestSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Snackbar
        </Typography>
        <Stack sx={{ mt: 2 }} spacing={2} direction="row">
          <Button
            color="inherit"
            onClick={() => enqueueSnackbar("This is a test")}
          >
            Show
          </Button>
          <Button
            color="error"
            onClick={() =>
              enqueueSnackbar("This is a test", { variant: "error" })
            }
          >
            Show Error
          </Button>
          <Button
            color="warning"
            onClick={() =>
              enqueueSnackbar("This is a test", { variant: "warning" })
            }
          >
            Show Warning
          </Button>
          <Button
            color="success"
            onClick={() =>
              enqueueSnackbar("This is a test", { variant: "success" })
            }
          >
            Show Success
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TestSnackbar;
