import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Donor } from "../../lib/types/donor";
import DonorSearchCard from "./DonorSearchCard";

type Props = {
  results?: Donor[];
};
const DonorSerachResults = ({ results }: Props) => {
  return (
    <div>
      <Typography variant="h5" component="div">
        Search Results
      </Typography>
      <Stack sx={{ mt: 2 }} spacing={2}>
        {results?.map((donor) => (
          <DonorSearchCard key={donor.id} donor={donor} />
        ))}
      </Stack>
    </div>
  );
};

export default DonorSerachResults;
