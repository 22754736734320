import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { Box } from '@mui/material';

type Props = {
}

export const Layout: FC<Props> = () => {
    return (
      <>
        <Header />
        <Box sx={{ mt: 9 }}>
          <Outlet />
        </Box>
      </>
    );
}