import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import territories from "../../lib/data/territory.json";
import bloodGroupes from "../../lib/data/bloodGroups.json";
import { useFormik } from "formik";
import * as yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";
import { LinearProgress } from "@mui/material";
import { searchBloodProps } from "../../lib/types";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const validationSchema = yup.object({
  bloodType: yup.string().required("Required"),
  state: yup.string().required("Required"),
  district: yup.string().required("Required"),
});

type Props = {
  onSubmit: (values: searchBloodProps) => void;
  onCancel: () => void;
};

export default function LandingSearchPanel({ onSubmit, onCancel }: Props) {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [searching, setSearching] = useState(false);

  const states = Object.values(territories.states).map((key) => key.name);

  const formik = useFormik({
    initialValues: {
      bloodType: bloodGroupes[0],
      state: states[0],
      district: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const districts = Object.values(territories.states).find(
    (key) => key.name === formik.values?.state
  )?.districts;

  const handleCancel = () => {
    setSearching(false);
    onCancel();
  };

  useEffect(() => {
    const bloodType = searchParams.get("bloodType");
    const state = searchParams.get("state");
    const district = searchParams.get("district");
    if (bloodType) {
      formik.setFieldValue("bloodType", bloodType);
    }
    if (state) {
      formik.setFieldValue("state", state);
    }
    if (district) {
      formik.setFieldValue("district", district);
    }

    if (!bloodType || !state || !district) {
      formik.setValues({
        bloodType: bloodGroupes[0],
        state: states[0],
        district: districts ? districts[0] : "",
      });
    }
  }, []);

  if (searching) {
    const { bloodType, state, district } = formik.values;
    return (
      <Container>
        <Box sx={{ mt: 20 }}>
          <Typography variant="h3" sx={{ mb: 2 }} color={"primary"}>
            Searching...
          </Typography>
          <Typography variant="body1" color={"GrayText"}>
            Looking for {bloodType} blood donors available in {district} of{" "}
            {state}
          </Typography>
          <LinearProgress sx={{ my: 2 }} />
          <Button
            variant="outlined"
            color="inherit"
            sx={{ mt: 1 }}
            onClick={handleCancel}
          >
            Modify Search
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 20 }}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Find the blood you need
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{ my: 4 }}
          >
            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>Blood Group</InputLabel>
              <Select
                label="Blood Group"
                name="bloodType"
                value={formik.values.bloodType}
                onChange={formik.handleChange}
                error={
                  formik.touched.bloodType && Boolean(formik.errors.bloodType)
                }
              >
                {bloodGroupes.map((bloodType) => (
                  <MenuItem key={bloodType} value={bloodType}>
                    {bloodType}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.bloodType && formik.errors.bloodType && (
                <FormHelperText error>{formik.errors.bloodType}</FormHelperText>
              )}
            </FormControl>

            <FormControl sx={{ minWidth: 150 }}>
              <InputLabel>State</InputLabel>
              <Select
                label="State"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
              >
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.state && formik.errors.state && (
                <FormHelperText error>{formik.errors.state}</FormHelperText>
              )}
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
              <InputLabel>District</InputLabel>
              <Select
                label="District"
                name="district"
                value={formik.values.district}
                onChange={formik.handleChange}
                error={
                  formik.touched.district && Boolean(formik.errors.district)
                }
                disabled={!formik.values.state}
              >
                {districts?.map((d) => (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.district && formik.errors.district && (
                <FormHelperText error>{formik.errors.district}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                // disabled={!bloodType || !state || !district}
                sx={{ py: { xs: 2, md: 2 } }}
                type="submit"
              >
                Search
              </Button>
            </FormControl>
          </Stack>
        </form>
      </Box>
    </Container>
  );
}
