import MUILink, { LinkProps as MUILinkProps } from "@mui/material/Link";

//muibutton properties
type Props = {
    children: React.ReactNode,
    href: string
} & MUILinkProps;

export function PageLink({ children, href, ...rest }: Props) {
    return (
        <MUILink underline="none" href={href} {...rest}>
            {children}
        </MUILink>
    )
}