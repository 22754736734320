import { Box, Button, Container, Typography } from "@mui/material"
import { useEffect } from "react"
import { PageLink } from "../../components/custom/PageLink"

export const NotFound = () => {

    useEffect(() => {
        document.title = 'Page Not Found - Blood Bank'
    }, [])

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'center', height: '80vh', alignItems: 'center' }}>
                <Box textAlign={'center'}>
                    <Typography variant="h1" color={'primary'} sx={{ mb: 2 }}>404</Typography>
                    <Typography variant="h4" sx={{ mb: 4 }}>Page Not Found</Typography>
                    <Button href="/" sx={{ ml: 2 }} variant="outlined" color={'inherit'}>Go Home</Button>
                </Box>
            </Box>
        </Container>
    )
}

export default NotFound