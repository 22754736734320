import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import React, { FC } from "react";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { PageLink } from "../custom/PageLink";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const Header: FC = () => {
  return (
    <AppBar
      position="fixed"
      sx={{ boxShadow: "none", backgroundColor: "white", color: "black" }}
    >
      <Container>
        <Box sx={{ py: 2, display: "flex", alignItems: "center" }}>
          <PageLink href="/">
            <Typography
              variant="h4"
              component="div"
              sx={{ flexGrow: 1, fontSize: "1.5rem" }}
            >
              <b>Blood Bank</b>
            </Typography>
          </PageLink>
          <Box sx={{ ml: "auto" }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="contained"
                href="/find-donors"
                startIcon={<SearchIcon />}
              >
                Find Donor
              </Button>

              <Link
                variant="button"
                color={"gray"}
                href="/register"
                underline="none"
              >
                Register
              </Link>
            </Stack>
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header