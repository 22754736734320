import { useEffect, useState } from "react";
import { bbBackendApi } from "../api/api";
import { Donor } from "../types/donor";

interface Props {
  bloodType?: string;
  state?: string;
  district?: string;
}

const useGetDonors = (props: Props) => {
  const [donors, setDonors] = useState<Donor[]>([]);

  useEffect(() => {
    console.info("useGetDonors props changed", JSON.stringify(props));

    bbBackendApi.get("/api/users").then((response) => {
      setDonors(response.data);
    });
  }, [JSON.stringify(props)]);

  return donors;
};

export default useGetDonors;
