import {
  Box,
  Card,
  CardContent,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import TestSnackbar from "./components/TestSnackbar";

const TestPage = () => {
  return (
    <Container>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3" gutterBottom>
            Test Page
          </Typography>
          <Typography variant="body2">To try everything</Typography>
        </CardContent>
      </Card>
      <Box sx={{ mt: 2 }}>
        <TestSnackbar />
      </Box>
    </Container>
  );
};

export default TestPage;
