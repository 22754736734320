import './App.css';
import { Layout } from './components/UI/Layout';
import { Routes, Route } from 'react-router';
import Home from './pages/home/Home';
import RegisterLanding from './pages/register/RegisterLanding';
import NotFound from './pages/common/notFound';
import SearchLanding from "./pages/search/SearchLanding";
import TestPage from "./pages/test/TestPages";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="find-donors" element={<SearchLanding />} />
          <Route path="register" element={<RegisterLanding />} />
          <Route path="test" element={<TestPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
